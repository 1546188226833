import "./BotanicHub.scss";
import GoToTop from "../../components/GoToTop/GoToTop";
import BotanicHubHero from "../../assets/images/botanic_hub_mobile-view.svg";
import BotanicHubSketch1 from "../../assets/images/botanic_hub_sketch1.png";
import BotanicHubSketch2 from "../../assets/images/botanic_hub_sketch2.png";
import BotanicHubSketch3 from "../../assets/images/botanic_hub_sketch3.png";
import BotanicHubLoFi from "../../assets/images/botanic_hub_lo-fi_wireframes.svg";
import BotanicHubHiFiMobile from "../../assets/images/botanic_hub_hi-fi_mobile.svg";
import BotanicHubHiFiTablet from "../../assets/images/botanic_hub_hi-fi_tablet.svg";
import BotanicHubHiFiDesktop from "../../assets/images/botanic_hub_hi-fi_desktop.svg";
import BotanicHubDesignSystem from "../../assets/images/botanic_hub_design_system.svg";

let BotanicHub = () => {
  return (
    <main className="main-container">
      <section className="main projects">
        <span className="feature__title">
          <h1 className="feature__title__text">BOTANIC HUB</h1>
          <div className="feature__title__line" />
        </span>
        <img src={BotanicHubHero} className="projects__hero" alt="hero" />
        <a
          href="https://botanic-hub.netlify.app/"
          target="_blank"
          rel="noreferrer"
          className="button-anchor"
        >
          <button className="button button-w-link">View website </button>
        </a>
        <h4 className="projects__subheader">Design Process</h4>
        <article className="botanic__article">
          <h4 className="botanic__subtitle">CONCEPT & INITIAL DESIGNS</h4>
          <img
            src={BotanicHubSketch1}
            className="botanic__sketch"
            alt="initial ideas"
          ></img>
          <img
            src={BotanicHubSketch2}
            className="botanic__sketch"
            alt="user journey"
          ></img>
          <img
            src={BotanicHubSketch3}
            className="botanic__sketch"
            alt="initial sketches"
          ></img>
        </article>
        <article className="botanic__article">
          <h4 className="botanic__subtitle">LO-FI WIREFRAMES</h4>
          <img
            src={BotanicHubLoFi}
            className="botanic__full-image"
            alt="initial ideas"
          ></img>
        </article>
        <article className="botanic__article">
          <h4 className="botanic__subtitle">HI-FI WIREFRAMES</h4>
          <h4>MOBILE</h4>
          <img
            src={BotanicHubHiFiMobile}
            className="botanic__full-image"
            alt="mobile hi-fi"
          ></img>
          <h4>TABLET</h4>
          <img
            src={BotanicHubHiFiTablet}
            className="botanic__full-image"
            alt="tablet hi-fi"
          ></img>
          <h4>DESKTOP</h4>
          <img
            src={BotanicHubHiFiDesktop}
            className="botanic__full-image"
            alt="desktop hi-fi"
          ></img>
        </article>
        <article className="botanic__article">
          <h4 className="botanic__subtitle">DESIGN SYSTEM</h4>
          <img
            src={BotanicHubDesignSystem}
            className="botanic__full-image"
            alt="design system"
          ></img>
        </article>
      </section>
      <GoToTop />
    </main>
  );
};

export default BotanicHub;
